
const ORDER_TOKEN_KEY = 'hubrise_order_token';
const CATALOG_TOKEN_KEY = 'hubrise_catalog_token';
const LOCATION_KEY = 'hubrise_location';
const DISPO_KEY = 'disponibilite_status';
const CATALOG_KEY = 'catalog_obj';
const CATALOG_DATA_KEY = 'all_catalogs';
const REFRESH_KEY = 'refresh_key_time';
const PRINTER_IP = 'printer_ip_address';
const AUTO_PRINT = 'print_auto_print';
const DISPO_STATUS = {
    dispo: 'dispo',
    indispo: 'indispo'
}

const getPrinterIp = () => {
    if (typeof window === 'undefined') return;
    return window.localStorage.getItem(PRINTER_IP);
}

const setPrinterIp = (value) => {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(PRINTER_IP, value);
}

const getAutoPrint = () => {
    if (typeof window === 'undefined') return;
    const val = window.localStorage.getItem(AUTO_PRINT);
    console.log(val);
    return val === undefined || val === null ? true :
        val === 'false' ? false :
            val === 'true' ? true :
                val;
}

const setAutoPrint = (value) => {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(AUTO_PRINT, value);
}
const getOrderToken = () => {
    if (typeof window === 'undefined') return;
    return window.localStorage.getItem(ORDER_TOKEN_KEY);
}

const setOrderToken = (token) => {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(ORDER_TOKEN_KEY, token);
}
const getCatalogToken = () => {
    if (typeof window === 'undefined') return;
    return window.localStorage.getItem(CATALOG_TOKEN_KEY);
}

const setCatalogToken = (token) => {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(CATALOG_TOKEN_KEY, token);
}

const setLocation = (obj) => {
    if (typeof window === 'undefined') return;
    window.localStorage.setItem(LOCATION_KEY, JSON.stringify(obj));
}

const getLocation = () => {
    if (typeof window === 'undefined') return;
    let obj = window.localStorage.getItem(LOCATION_KEY);
    return JSON.parse(obj);
}

const clearToken = () => {
    if (typeof window === 'undefined') return;
    window.localStorage.removeItem(ORDER_TOKEN_KEY);
    window.localStorage.removeItem(CATALOG_TOKEN_KEY);
    window.localStorage.removeItem(CATALOG_DATA_KEY);
    window.localStorage.removeItem(CATALOG_KEY);
    window.localStorage.removeItem(DISPO_KEY);
    window.localStorage.removeItem(LOCATION_KEY);
    window.localStorage.removeItem(REFRESH_KEY);
    window.localStorage.removeItem(PRINTER_IP);
    window.localStorage.removeItem(AUTO_PRINT);
}

const setDisponibility = (value) => {
    if (typeof window === 'undefined') return;
    let targetValue = value ? DISPO_STATUS.dispo : DISPO_STATUS.indispo;
    window.localStorage.setItem(DISPO_KEY, targetValue);
}

const getDisponibility = () => {
    if (typeof window === 'undefined') return;
    return window.localStorage.getItem(DISPO_KEY);
}

const isDispo = () => {
    return getDisponibility() !== DISPO_STATUS.indispo;
}

const setCatalogObj = (value) => {
    if (typeof window === 'undefined') return;
    if (!value) return;
    window.localStorage.setItem(CATALOG_KEY, JSON.stringify(value));
}

const getCatalogObj = () => {
    if (typeof window === 'undefined') return;
    let obj = window.localStorage.getItem(CATALOG_KEY);
    return JSON.parse(obj);
}

const setAllCatsData = (value) => {
    if (typeof window === 'undefined') return;
    if (!value) return;
    window.localStorage.setItem(CATALOG_DATA_KEY, JSON.stringify(value));
}

const getAllCatsData = () => {
    if (typeof window === 'undefined') return;
    let obj = window.localStorage.getItem(CATALOG_DATA_KEY);
    return JSON.parse(obj);
}

const getAllPlats = () => {
    let items = [];
    let allCats = getAllCatsData();
    if (!allCats) return items;
    for (let cat of allCats) {
        let catData = { id: cat.id, name: cat.name };
        let plats = cat.plats.map(p => ({ ...p, category: catData }))
        items = items.concat(plats);
    }
    return items;
}

const getLasUpdatedTime = () => {
    let time = window.localStorage.getItem(REFRESH_KEY);
    return time || 0;
}

const setLastUpdatedTime = () => {
    window.localStorage.setItem(REFRESH_KEY, new Date().getTime());
}

module.exports = {
    getOrderToken,
    setOrderToken,
    setCatalogToken,
    getCatalogToken,
    clearToken,
    setLocation,
    getLocation,
    setDisponibility,
    isDispo,
    setCatalogObj,
    getCatalogObj,
    setAllCatsData,
    getAllCatsData,
    getAllPlats,
    setLastUpdatedTime,
    getLasUpdatedTime,
    getPrinterIp,
    setPrinterIp,
    setAutoPrint,
    getAutoPrint,
}