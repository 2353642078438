const storageService = require('./storage_service');
const config = require('../config/config.json');
const axios = require('axios');

const buildHeader = () => ({
    'Content-type': 'application/json',
    'Authorization': `Bearer ${storageService.getToken()}`
});

const getInventories = () => {
    /*const catalogs = storageService.getCatalogs();
    if (catalogs) {
        return new Promise((rs, rj) => rs({ data: {data: catalogs} }))
    }*/
    let headers = { ...buildHeader() };
    const params = {
        "filters[$and][0][partner][id][$eq]": storageService.getPartner().id
    }
    return axios.get(`${config.baseAPI}/api/catalogs`, { headers, params });
}



module.exports = {
    getCatalogs: () => {
        /*const catalogs = storageService.getCatalogs();
        if (catalogs) {
            return new Promise((rs, rj) => rs({ data: {data: catalogs} }))
        }*/
        let headers = { ...buildHeader() };
        const params = {
            "filters[$and][0][partner][id][$eq]": storageService.getPartner().id
        }
        return axios.get(`${config.baseAPI}/api/catalogs`, { headers, params });
    },
    getCatalogData: (catalogId) => {
        const params = { merge_product: true }
        return axios.get(`${config.baseAPI}/api/catalogs-data/${catalogId}`, { headers: buildHeader(), params })
    },
    updateInventories: (inventories) => {
        return axios.put(`${config.baseAPI}/api/catalogs-data/inventory`, inventories, { headers: buildHeader() })
    },
    getInventories: () => axios.get(`${config.baseAPI}/api/catalogs-data/inventory`, { headers: buildHeader() })
}