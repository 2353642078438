import React from "react";
import { Box, Layer, Text } from "grommet";
import { COLOR, FONT_SIZE } from "../../services/utils";
import { Close, Menu, Pause, Play } from "grommet-icons";
import StandardText from "../elements/standard_text";
import { navigate } from "gatsby";

const LeftMenu = ({
  toDisponibility,
  toHoraire,
  toPrinter,
  pauseRestaurant,
  toHolidayMode,
  onClose,
}) => {
  return (
    <Layer position="top-left" onClickOutside={onClose}>
      <Box
        justify="between"
        style={{ height: "100vh", width: 700, zIndex: 999, top: 0 }}
        direction="column"
        border={{ color: COLOR.dark_blue, side: "right" }}
        background={COLOR.light_blue}
        animation={{ type: "slideDown", duration: 1000 }}
      >
        <Box width={"full"} direction="column">
          <Box
            pad={"xsmall"}
            background={COLOR.dark_blue}
            justify="between"
            align="center"
            width={"full"}
            direction="row"
          >
            <Box>
              <Menu size="large" color={COLOR.light_blue} fontWeight={"bold"} />
            </Box>
            <Box onClick={onClose}>
              <Close color={COLOR.light_blue} size="large" />
            </Box>
          </Box>
          <Box pad={"medium"} gap="large" align="start">
            <Box
              style={{ boxShadow: "none", height: "100%" }}
              pad="small"
              onClick={toDisponibility}
            >
              <Text
                color={"#033076"}
                style={{ cursor: "pointer", fontSize: FONT_SIZE.xlarge }}
              >
                Disponibilité du stock
              </Text>
            </Box>
            <Box
              style={{ boxShadow: "none", height: "100%" }}
              pad="small"
              onClick={toHoraire}
            >
              <Text
                color={"#033076"}
                style={{ cursor: "pointer", fontSize: FONT_SIZE.xlarge }}
              >
                Horaires
              </Text>
            </Box>
            <Box
              style={{ boxShadow: "none", height: "100%" }}
              pad="small"
              onClick={toPrinter}
            >
              <Text
                color={"#033076"}
                style={{ cursor: "pointer", fontSize: FONT_SIZE.xlarge }}
              >
                Imprimante
              </Text>
            </Box>
          </Box>
        </Box>
        <Box
          width={"full"}
          direction="column"
          margin={{ bottom: "large" }}
          pad={"medium"}
          gap="medium"
        >
          <Box
            width={"full"}
            direction="row"
            gap="small"
            pad={"large"}
            align="center"
            onClick={pauseRestaurant}
          >
            <Box direction="row">
              <Play color={COLOR.dark_blue} size="large" />
              <Pause color={COLOR.dark_blue} size="large" />
            </Box>
            <StandardText
              label={"Pause des commandes"}
              style={{ fontSize: FONT_SIZE.large }}
              color={COLOR.dark_blue}
            />
          </Box>
          <Box
            style={{ boxShadow: "none", height: "100%" }}
            pad="small"
            onClick={toHolidayMode}
          >
            <Text
              color={"#033076"}
              style={{ cursor: "pointer", fontSize: FONT_SIZE.xlarge }}
            >
              Mode vacances
            </Text>
          </Box>
          <Box
            style={{ boxShadow: "none", height: "100%" }}
            pad="small"
            onClick={() => navigate('/update')}
          >
            <Text
              color={COLOR.green}
              style={{ cursor: "pointer", fontSize: FONT_SIZE.xlarge }}
            >
              Mettre à jour
            </Text>
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};

export default LeftMenu;
