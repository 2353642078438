

import {
    Box, Button, Layer, Tab, Tabs, Text, TextInput
} from "grommet";
import { CaretDownFill, CaretRightFill, Search as SearchIcon } from "grommet-icons";
import React from "react";
import BlockUi from "react-block-ui";
import service from '../../services/catalog_service';
import { COLOR, FONT_SIZE } from "../../services/utils";
import LoadingAnimation from "../elements/loading_animation";
import CustomToggle from "../elements/toggle";

const containsInList = (item, arr) => {
    const val = arr.find(a => a.model_type === item.model_type && item.id === a.id);
    return val ? true : false;
}

const Header = ({ onClose }) => {

    return (
        <Box background='#e6f3fe' justify="between" align="center" width={'full'}
            direction='row'
        >
            <Text size="xlarge" color={COLOR.dark_blue}></Text>
            <Box width={'full'} pad={'small'} >
                <Box style={{ boxShadow: 'none' }} alignSelf='center' pad={'small'}>
                    <Text style={{ fontSize: FONT_SIZE.large }} textAlign="center" color={'#033076'}> Disponibilité du plat </Text>
                </Box>
            </Box>
            <Box onClick={onClose} style={{ boxShadow: 'none', position: 'absolute', right: 30 }}  >
                <Text size="xlarge" color={COLOR.dark_blue}>Fermer et enregistrer</Text>
            </Box>
        </Box>
    )
}

class DisponibilitePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            plats: [],
            allCats: [],
            query: '',
            inventories: [],
            newDispoInvs: [],
            newInDispoInvs: [],
            openConfirmation: false,
            allPlats: []
        }
    }

    componentDidMount = () => {
        this.loadCatalogs();
        this.loadAllInventories();
    }

    loadCatalogs = () => {
        service.getCatalogs()
            .then(res => {
                const catalogs = res.data.data;
                //storageService.setCatalogs(catalogs);
                this.setState({ allCats: catalogs });
            })
    }

    loadAllInventories = () => {
        service.getInventories()
            .then(res => {
                this.setState({ inventories: res.data })
                console.log(res.data);
            })
    }

    search = (query) => {

    }

    onLoadOptions = async (plat) => {
    }

    updateInventoryAndClose = async () => {
        this.setState({ loading: true });
        let isOk = false;
        try {
            const newInventories = []
            const restingDispo = this.state.inventories.filter(inv => !containsInList(inv, this.state.newDispoInvs));
            let inventories = [...restingDispo, ...this.state.newInDispoInvs];
            for (const inv of inventories) {
                if (inv.model_type === 'option') {
                    newInventories.push(inv);
                }
                if (inv.skus?.length) {
                    newInventories.push(inv.skus[0]);
                }
            }

            console.log(newInventories);

            await service.updateInventories(newInventories);
            this.props.onClose();
            this.setState({ loading: false });
            this.props.updateInventoryHook(true);
        } catch (err) {
            console.log(err);
            this.props.onClose();
            this.setState({ loading: false });
            this.props.updateInventoryHook(isOk);
        }
    }

    onDispoChange = (newDispoInvs) => {
        this.setState({ newDispoInvs: newDispoInvs.map(s => s) });
    }

    onInDispoChange = (newInDispoInvs) => {
        this.setState({ newInDispoInvs: newInDispoInvs.map(s => s) });
    }

    selectTab = async index => {
        if (index > 0) {
            const catalog = this.state.allCats[index - 1];
            if (!catalog.plats) {
                service.getCatalogData(catalog.id)
                    .then(res => {
                        const { products } = res.data;
                        catalog.plats = products;
                        this.setState({ allCats: [...this.state.allCats] })
                    })
            }
        }
    }

    onClose = () => {
        let changed = this.state.newDispoInvs.length || this.state.newInDispoInvs.length;
        if (!changed) {
            this.props.onClose();
        } else {
            this.setState({ openConfirmation: true });
        }
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                <Box width={'full'}>
                    <Header onClose={this.onClose}
                    />
                    <Search
                        onSearch={this.search}
                        query={this.state.query}
                        setQuery={query => this.setState({ query })}
                    />
                    {this.state.openConfirmation &&
                        (<Layer style={{ background: 'transparent' }}>
                            <Box background={COLOR.dark_blue} pad={'medium'} justify='center' align="center" gap="large" round={{ size: 'medium' }}>
                                <Box style={{ height: '100%' }} justify='center' align="center">
                                    {this.props.disable_inventory ?
                                        (<Text color={COLOR.light_blue} style={{ fontSize: FONT_SIZE.medium }} textAlign='center'>
                                            Veuillez attendre 1 minute entre chaque modification.
                                        </Text>) :
                                        (<Text color={COLOR.light_blue} style={{ fontSize: FONT_SIZE.medium }} textAlign='center'>
                                            Souhaitez-vous enregistrer <br /><br />
                                            les modifications suivantes ?
                                        </Text>)
                                    }
                                </Box>

                                {this.props.disable_inventory ?
                                    (<Box direction="row" justify="between" align="center" gap="medium">
                                        <Button label='Fermer' onClick={() => this.setState({ openConfirmation: false })}
                                            size='large'
                                            style={{ background: COLOR.dark_blue, color: COLOR.light_blue, border: 'none' }}
                                        />
                                    </Box>) :
                                    (<Box direction="row" justify="between" align="center" gap="medium">
                                        <Button label='NON' onClick={() => this.props.onClose()}
                                            size='large'
                                            style={{ background: COLOR.dark_blue, color: COLOR.light_blue, border: 'none' }}
                                        />
                                        <Button
                                            size="large"
                                            label='OUI' style={{ background: COLOR.light_blue, color: COLOR.dark_blue, border: 'none' }}
                                            onClick={this.updateInventoryAndClose}
                                        />
                                    </Box>)
                                }

                            </Box>
                        </Layer>)
                    }

                    {this.state.query && (
                        <Box width={'full'} style={{ paddingTop: 30 }}>
                            {this.state.plats
                                .map((plat, index) => (
                                    <CollapsiblePlat
                                        key={index}
                                        plat={plat}
                                        showFirst={index === 1 ? true : false}
                                        inventories={this.state.inventories}
                                        newDispoInvs={this.state.newDispoInvs}
                                        newInDispoInvs={this.state.newInDispoInvs}
                                        onDispoChange={this.onDispoChange}
                                        onInDispoChange={this.onInDispoChange}
                                    />
                                ))}
                        </Box>
                    )}
                    {!this.state.query && (
                        <Box width={'full'} style={{ paddingTop: 30 }}>
                            <Tabs onActive={(idx) => this.selectTab(idx)}>
                                <Tab key={112} title={<Text size="xxlarge" style={{ fontWeight: 'bold' }}>{'INDISPONIBLE'}</Text>}>
                                    <Box pad={'medium'} width='full' gap="small">
                                        <Box gap="small" width='full'>
                                            {this.state.inventories
                                                .filter(p => p.skus?.length)
                                                .map((plat, index) => (
                                                    <CollapsiblePlat
                                                        key={index}
                                                        plat={plat}
                                                        inventories={this.state.inventories}
                                                        newDispoInvs={this.state.newDispoInvs}
                                                        newInDispoInvs={this.state.newInDispoInvs}
                                                        onInDispoChange={this.onInDispoChange}
                                                        onDispoChange={this.onDispoChange}
                                                    />
                                                ))}
                                        </Box>
                                    </Box>
                                </Tab>
                                {this.state.allCats.map((cat, id) => {
                                    return (
                                        <Tab key={id} title={<Text size="xxlarge">{cat.name}</Text>} >
                                            <Box pad={'medium'} width='full' gap="small">
                                                {cat.plats ?
                                                    <Box gap="small" width='full'>
                                                        {cat.plats
                                                            .filter(p => p.skus[0].stock !== 0)
                                                            .map((plat, index) => (
                                                                <CollapsiblePlat
                                                                    key={index}
                                                                    plat={plat}
                                                                    inventories={this.state.inventories}
                                                                    newDispoInvs={this.state.newDispoInvs}
                                                                    newInDispoInvs={this.state.newInDispoInvs}
                                                                    onDispoChange={this.onDispoChange}
                                                                    onInDispoChange={this.onInDispoChange}
                                                                />
                                                            ))}
                                                    </Box> : <LoadingAnimation />
                                                }
                                            </Box>
                                        </Tab>
                                    )
                                })}
                            </Tabs>
                        </Box>
                    )}
                </Box>
            </BlockUi>
        )
    }
}

const CollapsiblePlat = ({ plat, onInDispoChange, onDispoChange, inventories, newInDispoInvs, newDispoInvs, showFirst = false }) => {

    const [expanded, setExpanded] = React.useState(showFirst);
    const onExpanded = () => {
        setExpanded(!expanded);
    }


    const _onDispoChange = (item, isDispo) => {
        let newList;
        if (isDispo) {
            if (containsInList(item, inventories)) {
                newDispoInvs.push(item);
                onDispoChange(newDispoInvs);
            } else {
                newList = newInDispoInvs.filter(i => i.id !== item.id);
                onInDispoChange(newList);
            }
        } else {
            if (containsInList(item, newDispoInvs)) {
                newList = newDispoInvs.filter(i => i.id !== item.id);
                onDispoChange(newList);
            } else {
                newInDispoInvs.push(item);
                onInDispoChange(newInDispoInvs);
            }
        }
        console.log(newDispoInvs, newInDispoInvs, newList);
    }

    return (
        <Box width={'full'} justify="center" style={{ boxShadow: 'none' }}>
            <Box direction="row" justify="between" width={'full'} pad='small' align="center">
                <Box direction="row" gap="small" onClick={onExpanded} style={{ boxShadow: 'none' }} justify='center' align="center" >
                    {!expanded ?
                        <CaretRightFill color={COLOR.light_blue} size="xlarge" /> :
                        <CaretDownFill fill="#c8c8c8" color={COLOR.light_blue} size="xlarge" />
                    }
                    <Text style={{ fontSize: FONT_SIZE.large }} color={COLOR.dark_blue}>{plat.name || 'Inconnu'}</Text>
                </Box>
                <Box direction="row" align="center" justify="center" gap="small">
                    <CustomToggle
                        onChange={event => _onDispoChange(plat, event.target.checked)}
                        checked={containsInList(plat, newDispoInvs) || (!containsInList(plat, newInDispoInvs) && !containsInList(plat, inventories))}
                    />
                </Box>
            </Box>

            {expanded &&
                <Box width={'full'} pad='xsmall' justify="center" align="center">
                    {
                        (plat.options || []).map((option, index) => (
                            <PlatOption
                                key={index}
                                optionList={option}
                                onOptionDispoChange={_onDispoChange}
                                plat_dispo={containsInList(plat, newDispoInvs) || (!containsInList(plat, newInDispoInvs) && !containsInList(plat, inventories))}
                                inventories={inventories}
                                newDispoInvs={newDispoInvs}
                                newInDispoInvs={newInDispoInvs}
                                category={plat.category}
                            />
                        ))
                    }
                </Box>
            }
        </Box>
    )
}

const PlatOption = ({ optionList, onOptionDispoChange, inventories, plat_dispo, newInDispoInvs, newDispoInvs, category }) => {

    return (
        <Box width={'full'}
            style={{ paddingLeft: 100 }}
            gap='xsmall'
        >
            <Text size="xlarge" color={COLOR.dark_blue}>{optionList.name}</Text>
            {
                optionList.options.map((sub, index) => {
                    sub.category = { ...category };
                    return (
                        <Box style={{ paddingLeft: 40, width: '50%' }} key={index}>
                            <Box direction="row" gap="small" justify='between'>
                                <Text size="large" color={COLOR.dark_blue}>{sub.name}</Text>
                                <CustomToggle
                                    onChange={event => onOptionDispoChange(sub, event.target.checked)}
                                    checked={plat_dispo && (containsInList(sub, newDispoInvs) || (!containsInList(sub, newInDispoInvs) && !containsInList(sub, inventories)))}
                                />
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

const Search = ({ onSearch, setQuery, query }) => {
    return (
        <Box width={'full'}
            direction='row'
            pad={'small'}
            round={{ size: 'medium' }}
            border={{ size: 'small', color: '#033076' }}
        >
            <TextInput plain
                value={query}
                placeholder='Cherche un plat'
                size="xxlarge"
                icon={<SearchIcon />}
                style={{ boxShadow: 'none' }}
                onChange={event => {
                    setQuery(event.target.value);
                    onSearch(event.target.value);
                }} />

        </Box>
    )
}

export default DisponibilitePage;