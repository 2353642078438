import { Box, Text } from "grommet";
import React, { useEffect, useState } from "react";
import { COLOR } from "../../services/utils";

const NewCommandSignal = ({ tap, playAlert }) => {
  const [bgColor, setBgColor] = useState(COLOR.dark_blue);
  const [frontColor, setFrontColor] = useState(COLOR.light_blue);
  const [timeoutId, setTimeoutId] = useState();

  const flashLight = (_bgColor, _frontColor) => {
    let _bg = _bgColor || COLOR.dark_blue;
    let _front = _frontColor || COLOR.light_blue;
    setBgColor(_bg);
    setFrontColor(_front);
    playAlert();
    let _timeoutId = setTimeout(() => flashLight(_front, _bg), 2500);
    setTimeoutId(_timeoutId);
  };

  useEffect(() => {
    flashLight();
  }, []);

  const onTap = () => {
    clearTimeout(timeoutId);
    tap();
  };

  return (
    <Box width={"full"} background={bgColor} onClick={onTap}>
      <Box
        height={"100vh"}
        width="full"
        align="center"
        alignSelf="center"
        justify="center"
      >
        <Box align="center" gap="medium">
          <Text
            color={frontColor}
            style={{ fontSize: 100, fontWeight: "bold" }}
          >
            NOUVELLE
          </Text>
          <br />
          <Text
            color={frontColor}
            style={{ fontSize: 100, fontWeight: "bold" }}
          >
            COMMANDE !
          </Text>
          <br />
          <Text
            color={frontColor}
            style={{
              fontSize: 44,
              textDecoration: "underline",
              fontStyle: "italic",
            }}
          >
            CLIQUER POUR LA VOIR
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default NewCommandSignal;
