

import {
    Avatar,
    Box,
    Text
} from "grommet";
import { Bike, History, LinkNext, Menu } from 'grommet-icons';
import moment from "moment";
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import deliveroo from '../../images/deliveroo.png';
import ubereat from '../../images/ubereat.png';
import { COLOR, FONT_SIZE, getTitle } from "../../services/utils";
import HelpPopup from "../help_popup";
import ProcessingCommande from '../processing_commande';
import ProblemPopup from "./problem_popup";
import RiderPopup from "./rider_popup";

const isDeliveroo = c => c.platform === "DELIVEROO";

const getRider = c => {
    if (c.riders?.length) {
        const rider = c.riders[0];
        return {
            name: rider.full_name || rider.first_name,
            expected_pickup_at: rider.estimated_arrival_time || rider.estimated_pickup_time,
            code: rider.bridge_code || rider.phone,
            number: rider.bridge_number || rider.phone_code
        }
    }
}
const getRiderTime = c => {
    const rider = getRider(c);
    return rider ? rider.expected_pickup_at : c.expected_pickup_at
}

const Header = ({ toHistoryPage, toSettingPage, help, currentTime }) => {

    return (
        <Box background='#e6f3fe' justify="between" align="center" width={'full'}
            direction='row'
        >
            <Box direction="row" gap='small' align="center">
                <Box pad={'small'} style={{ left: 7 }} onClick={toSettingPage}>
                    <Menu size="large" color="#033076" fontWeight={'bold'} />
                </Box>
            </Box>
            <Box pad={'small'} style={{ boxShadow: 'none' }}>
                <Text textAlign="center" color={'#033076'} style={{ fontWeight: 'bold', fontSize: 60 }}> {currentTime} </Text>
            </Box>
            <Box direction="row" gap='small' align="center">
                <Box onClick={help} style={{ boxShadow: 'none', paddingLeft: 30, paddingRight: 30, paddingTop: 5, paddingBottom: 5 }} round={{ size: 'medium' }}
                    background={COLOR.foreground_disabled}
                    border={{ color: COLOR.dark_blue, size: 'medium' }}
                >
                    <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold' }}>AIDES</Text>
                </Box>
                <Box pad={'small'} onClick={toHistoryPage} style={{ boxShadow: 'none' }}>
                    <History size="large" color="#033076" />
                </Box>
            </Box>
        </Box>
    )
}

const HomePage = ({
    processingCommandes = [],
    terminatedCommandes = [],
    processingCommande,
    validateCommande,
    toHistoryPage,
    toSettingPage,
    showMessage,
    currentTime
}) => {

    const [problem, setProblem] = React.useState();
    const [rider, setRider] = React.useState();
    const [help, setHelp] = React.useState(false);
    const [selectedCommande, setSelectedCommande] = React.useState(processingCommande);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setSelectedCommande(processingCommande);
    }, [processingCommande]);

    const updateVersion = async () => {
        window.location.reload();
    }

    return (
        <BlockUi tag="div" blocking={loading}>
            <Box width="full" justify="center" align="center" >
                <Header
                    toHistoryPage={toHistoryPage}
                    toSettingPage={toSettingPage}
                    help={() => setHelp(true)}
                    refresh={updateVersion}
                    currentTime={currentTime}
                />

                {help && <HelpPopup setHelp={setHelp} /> }
                {problem &&
                    <ProblemPopup setProblem={setProblem} order={problem} />
                }
                {rider &&
                    <RiderPopup setRider={setRider} order={rider} />
                }
                {processingCommandes.length === 0 && terminatedCommandes.length === 0 ?

                    <Box width='full' align="center" justify="center" alignContent="center" alignSelf="center" style={{ minHeight: 200 }}>
                        <Text style={{ fontSize: 60 }} textAlign="center" color={'#033076'}>Les commandes s'afficheront ici</Text>
                    </Box> :

                    <Box width="full" justify="center" align="center" pad={'small'} direction='row' gap="medium">
                        <Box style={{ width: '50%' }} justify="center" >
                            <Box style={{ height: 360 }}>
                                <CommandeDisplay
                                    title={"En prépation"}
                                    commandes={processingCommandes}
                                    onSelect={c => setSelectedCommande(c)}
                                    selectedCommande={selectedCommande}
                                    onProblemClick={c => setProblem(c)}
                                    onRiderClick={c => setRider(c)}
                                />
                            </Box>
                            <Box style={{ height: 20 }} />
                            <Box style={{ height: 360 }}>
                                <CommandeDisplay
                                    title={"Terminée"}
                                    commandes={terminatedCommandes}
                                    onSelect={c => setSelectedCommande(c)}
                                    selectedCommande={selectedCommande}
                                    onProblemClick={c => setProblem(c)}
                                    onRiderClick={c => setRider(c)}
                                />
                            </Box>

                        </Box>
                        <Box style={{ width: '50%' }} justify="center" align="center">
                            {selectedCommande &&
                                <ProcessingCommande
                                    setLoading={setLoading}
                                    commande={selectedCommande}
                                    onValidate={validateCommande}
                                    showMessage={showMessage}
                                />
                            }
                        </Box>
                    </Box>

                }
            </Box>
        </BlockUi>
    )
}

const CommandeDisplay = ({ commandes, title, selectedCommande, onSelect, onProblemClick, onRiderClick }) => {

    const isSelected = c => c.id === selectedCommande?.id;
    const getBgColor = c => isSelected(c) ? COLOR.dark_blue : COLOR.light_blue;
    const getFrColor = c => isSelected(c) ? COLOR.light_blue : COLOR.dark_blue;
    const getProvider = c => isDeliveroo(c) ? deliveroo : ubereat;
    const getCommandeTime = c => moment(c.placed_at).format('HH:mm');

    const [expectedTimes, setExpectedTimes] = useState([]);
    const [timeoutId, setTimeoutId] = useState();

    const reloadDeliverTime = () => {
        setExpectedTimes([...expectedTimes]);
        const id = setTimeout(reloadDeliverTime, 60 * 1000);
        setTimeoutId(id);
    }

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        if (commandes.length) {
            const _expectedTimes = commandes.map(c => ({ id: c.id, expectedTime: getRiderTime(c) }));
            setExpectedTimes(_expectedTimes);
        }
    }, [commandes]);

    const getExpectedTime = c => {
        const command = expectedTimes.find(e => e.id === c.id);
        if (!command || !command.expectedTime) return;
        const expected_pickup_at = command.expectedTime;
        if (moment().isBefore(moment(expected_pickup_at))) {
            return moment(expected_pickup_at).diff(moment(), 'minute') + 'min...';
        }
        return 'Arrivé';
    }

    return (
        <Box width={'full'} gap='small' style={{ height: 360, display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontWeight: 'bold', fontSize: 30 }} textAlign="center" color={COLOR.dark_blue}>{title} </Text>
            <Box style={{ overflowY: 'scroll' }} gap='xsmall'>
                {
                    commandes.map((c, index) => (
                        <div key={index} onClick={() => onSelect(c)} style={{
                            background: getBgColor(c), flexDirection: 'row', display: 'flex', borderRadius: 15, justifyContent: 'space-between',
                            paddingLeft: 10, paddingTop: 5, paddingBottom: 5
                        }}>
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}

                            >
                                <Avatar src={getProvider(c)} style={{ height: 40, width: 40 }} />
                                <span style={{ fontSize: FONT_SIZE.small, color: getFrColor(c) }}>
                                    {getTitle(c)}
                                </span>
                                <span style={{ fontSize: FONT_SIZE.small, color: getFrColor(c) }}>
                                    {getCommandeTime(c)}
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, paddingRight: 10 }}>
                                {getExpectedTime(c) ? <Box style={{ minHeight: 'auto', boxShadow: 'none' }} direction="row" gap="xsmall"
                                    justify="center" align="center" onClick={() => onRiderClick(c)}
                                >
                                    <Bike color={isSelected(c) ? COLOR.light_blue : COLOR.dark_blue} />
                                    <LinkNext size="small" color={isSelected(c) ? COLOR.light_blue : COLOR.dark_blue} />
                                    <Text color={isSelected(c) ? COLOR.light_blue : COLOR.dark_blue}>
                                        {getExpectedTime(c)}
                                    </Text>
                                </Box> : null}

                                <Box onClick={() => onProblemClick(c)} style={{ boxShadow: 'none', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5 }} round={{ size: 'medium' }}
                                    background={COLOR.foreground_disabled}
                                    border={{ color: COLOR.dark_blue, size: 'medium' }}
                                >
                                    <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold' }}>Problèmes ?</Text>
                                </Box>
                            </div>
                        </div>
                    ))
                }
            </Box>
        </Box>
    )
}
export default HomePage;

export {
    Header
};
