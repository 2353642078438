const moment = require("moment");
const storageService = require("./storage_service");
const config = require("../config/config.json");
const axios = require("axios");

const buildHeader = () => ({
  "Content-type": "application/json",
  Authorization: `Bearer ${storageService.getToken()}`,
});

const getRestaurentStatus = () => {
  let headers = { ...buildHeader() };
  return axios.get(`${config.baseAPI}/api/custom/partner/status`, { headers });
};

const updateRestaurantStatus = (willOpen) => {
  const status = willOpen ? "OPEN" : "PAUSED";
  let headers = { ...buildHeader() };
  return axios.patch(
    `${config.baseAPI}/api/custom/partner/status`,
    { status },
    { headers }
  );
};

const ping = async (hubrise_id) => {
  const params = { hubrise_id, version: storageService.getVersion() };
  const existingPartner = storageService.getPartner();
  if (existingPartner) {
    params.partner_id = existingPartner.id;
  }
  await axios
    .get(`${config.baseAPI}/api/custom/partner/ping`, {
      headers: { "Content-type": "application/json" },
      params,
    })
    .then(async (res) => {
      const { partner, jwt, user } = res.data;
      storageService.setPartner(partner);
      storageService.saveAuth({ jwt, user });

      return true;
    })
    .catch((err) => console.log(err));
};

const isRestoClose = () => {
  const partner = storageService.getPartner();
  if (!partner) return false;
  if (partner.opening_hours?.length) {
    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const today = weekdays[moment().isoWeekday() - 1];
    const time = partner.opening_hours.find((o) => o.label === today);
    if (time && !time.is_closed) {
      const thisMoment = moment().format("HH:mm");

      let from = time.opening[0].from;
      let to = time.opening[0].to === "00:00" ? "99:99" : time.opening[0].to;
      let opened = from < thisMoment && to > thisMoment;
      if (from > to) {
        // over midnight
        opened = !(thisMoment < from && thisMoment > to);
      }
      if (time.opening[1]) {
        from = time.opening[1].from;
        to = time.opening[1].to === "00:00" ? "99:99" : time.opening[1].to;
        opened = opened || (from < thisMoment && to > thisMoment);
        if (from > to) {
          // over midnight
          opened = opened || !(thisMoment < from && thisMoment > to);
        }
      }
      return !opened;
    }
  }
  return false;
};

const getNextOpenHour = () => {
  const partner = storageService.getPartner();
  if (!partner) return false;
  if (partner.opening_hours?.length && partner.opening_hours.filter(p => !p.is_closed).length) {
    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const findNextDayOpening = () => {
        // get the next opening day
        let nextDayIndx = moment().isoWeekday() % 7;
        let nextDay = weekdays[nextDayIndx];
        let nextTime = partner.opening_hours.find((o) => o.label === nextDay);
        while (!nextTime) {
          nextDayIndx++;
          nextDay = weekdays[nextDayIndx % 7];
          nextTime = partner.opening_hours.find((o) => o.label === nextDay);
        }
        return nextTime.opening[0].from;
    }
    let today = weekdays[moment().isoWeekday() - 1];
    let time = partner.opening_hours.find((o) => o.label === today);

    // There are 2 scenario, before opening and after opening
    // In case there are two open periods in the day, there are 5 scenario, before opening, inside the first period,
    // inside the pause, inside the second period, and after opening.

    if (today.is_closed || !time.opening?.length) {
      return findNextDayOpening();
    }
    const thisMoment = moment().format("HH:mm");
    // Only one period in the day
    if (time.opening.filter((o) => o.to && o.from).length === 1) {
      let from = time.opening[0].from;
      // before opening
      if (thisMoment <= from) return from;

      // after opening
      let to = time.opening[0].to === "00:00" ? "99:99" : time.opening[0].to;
      if (thisMoment >= to) {
        // get the next opening day
        return findNextDayOpening();
      }
    }
    // Two period in the day
    if (time.opening.filter((o) => o.to && o.from).length > 1) {
      const firstPeriod = time.opening[0];
      const secondPeriod = time.opening[1];
      // before opening
      if (thisMoment <= firstPeriod.from) return firstPeriod.from;
      // between pause
      if (firstPeriod.to < thisMoment && thisMoment < secondPeriod.from) {
        return secondPeriod.from;
      }

      // after opening
      let to = secondPeriod.to === "00:00" ? "99:99" : secondPeriod.to;
      if (thisMoment >= to) {
        return findNextDayOpening();
      }
    }
  }
  return "Inconnu";
};

const getPartnerInfo = () => {
  const params = {
    "filters[$and][0][account][id][$eq]": storageService.getUser().id,
  };
  return axios.get(`${config.baseAPI}/api/partners`, {
    headers: {
      Authorization: `Bearer ${storageService.getToken()}`,
    },
    params,
  });
};

const debug = (body) => {
  let headers = { ...buildHeader() };
  return axios.patch(`${config.baseAPI}/api/custom/partner/debug`, body, {
    headers,
  });
};

module.exports = {
  getRestaurentStatus,
  updateRestaurantStatus,
  ping,
  isRestoClose,
  getPartnerInfo,
  debug,
  getNextOpenHour,
};
