const moment = require('moment');
const storageService = require('./storage_service');
const config = require('../config/config.json');
const axios = require('axios');

const ORDER_STATUS = {
    COMPLETED: 'COMPLETED',
    ACCEPTED: 'ACCEPTED',
    NEW: 'NEW',
}

const buildHeader = () => ({
    'Content-type': 'application/json',
    'Authorization': `Bearer ${storageService.getToken()}`
});

const getNewCommandes = () => {
    let headers = { ...buildHeader() };
    const params = {
        "partnerId": storageService.getPartner().id,
    }
    return axios.get(`${config.baseAPI}/api/custom/orders`, { headers, params });
}


const getAllCommandes = async (meta) => {
    let headers = { ...buildHeader() };
    const params = {
        "filters[$and][1][placed_at][$gte]": moment().subtract(1, 'week').startOf('week').toISOString(),
        "filters[$and][0][partner_id][$eq]": storageService.getPartner().id,
        populate: 'items',
        sort: "createdAt:DESC"
    }
    params["pagination[page]"] = 1;
    params["pagination[pageSize]"] = 100;
    let res = await axios.get(`${config.baseAPI}/api/orders`, { headers, params });
    return res.data;
}

const changeStatus = (commande, status) => {
    let headers = { ...buildHeader() };
    return axios.patch(`${config.baseAPI}/api/custom/orders/${commande.id}/status`, { status: status }, {headers});
}


const getLatestCommandes = () => {
    let headers = { ...buildHeader() };
    let params = {
        "filters[$and][1][placed_at][$gte]": moment().subtract(1, 'hour').toISOString(),
        populate: '*',
        "filters[$and][0][partner_id][$eq]": storageService.getPartner().id
    }
    return axios.get(`${config.baseAPI}/api/orders`, { headers, params });
}

module.exports = {
    getNewCommandes,
    changeStatus,
    getAllCommandes,
    getLatestCommandes,
    ORDER_STATUS
}