
const moment = require('moment');
const { getPrinterIp } = require('./storage');
const { getTitle } = require('./utils');

const getDisplayedValue = (value, euro, ignoreZero) => {
    return ' ';
    let floatValue = value?.replace(' EUR', '') || 0;
    let displayedValue = parseFloat(floatValue) * 0.8;
    if (displayedValue === 0 && ignoreZero) return '';
    return !euro ?
        displayedValue.toFixed(2).replace('.', ',') :
        displayedValue.toFixed(2).replace('.', ',') + ' €'
}

const isDeliveroo = c => c.platform === 'DELIVEROO';
const getTitlePrint = c => {
    // max 24 char
    return getTitle(c);
}
const getSpaces = (prefix, suffix) => {
    let spaceCount = 48 - prefix.length - suffix.length;
    return " ".repeat(spaceCount);
}
const getItemsList = (c, printer) => {
    let items = c.items;
    for (let item of items) {
        printer.addTextStyle(false, false, true, printer.COLOR_1);
        let quantity = item.quantity;
        let prefix = `${quantity} X ${item.product_name}`
        let suffix = getDisplayedValue(item.subtotal, true);
        let text = prefix + getSpaces(prefix, suffix) + suffix;
        printer.addText(text);
        for (let option of (item.options || [])) {
            printer.addFeedLine(0);
            printer.addTextStyle(false, false, false, printer.COLOR_1);
            printer.addText(`  ${option.quantity}x ${option.name}`.toUpperCase());
        }
        printer.addFeedLine(1);
    }
}
const getTotalPaid = (c, printer) => {
    printer.addFeedLine(0);
    let prefix = 'Sous-total';
    let suffix = getDisplayedValue(c.total, true);
    let text = prefix + getSpaces(prefix, suffix) + suffix;
    printer.addText(text);
    printer.addFeedLine(0);
    printer.addTextStyle(false, false, true, printer.COLOR_1);
    prefix = 'Montant payé';
    text = prefix + getSpaces(prefix, suffix) + suffix;
    printer.addText(text);
    printer.addFeedLine(0);
}

const printCommande = (commande, setLoading, showMessage) => {
    let ip = getPrinterIp();
    if (!ip) {
        showMessage("l'Adresse d'IP d'imprimant manquant", 'error');
        return;
    }
    setLoading(true);
    var result = false;

    var printer = null;
    var ePosDev = new window.epson.ePOSDevice();
    setLoading(true);
    ePosDev.connect(ip, 8043, cbConnect);
    function cbConnect(data) {
        setLoading(false);
        if (data == 'OK' || data == 'SSL_CONNECT_OK') {
            ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER,
                { 'crypto': false, 'buffer': false }, cbCreateDevice_printer);
        } else {
            console.log(data);
            showMessage("L'imprimant non connecté sur l'adresse ip: " + ip + " " + data, "error");
        }
    }
    function cbCreateDevice_printer(devobj, retcode) {
        if (retcode == 'OK') {
            result = true;
            printer = devobj;
            printer.timeout = 30000;
            printer.onreceive = function (res) { console.log(res.success); };
            printer.oncoveropen = function () { console.log('coveropen'); };
            print();
        } else {
            console.log(retcode);
        }
    }

    function print() {
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addTextFont(printer.FONT_A);
        printer.addTextDouble(true, true);
        printer.addTextStyle(false, false, true, printer.COLOR_1);
        printer.addText(isDeliveroo(commande) ? "Deliveroo" : 'Uber Eats');
        printer.addFeedLine(2);
        printer.addTextSize(2, 2);
        printer.addTextFont(printer.FONT_A);
        printer.addTextStyle(false, false, true, printer.COLOR_1);
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addText(getTitlePrint(commande));
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addFeedLine(0);
        printer.addTextSize(1, 1);
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addText(`Commande passée le ${moment(commande.placed_at).format('DD/MM/YYYY à HH:mm')}`);
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addFeedLine(1);
        printer.addText(`À préparer pour le ${moment(commande.prepared_at).format('DD/MM/YYYY à HH:mm')}`);
        printer.addFeedLine(0);
        printer.addText('________________________________________________');
        printer.addFeedLine(2);
        printer.addTextFont(printer.FONT_A);
        printer.addTextSize(3, 3);
        printer.addFeedLine(0);
        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addText('LIVRAISON');
        printer.addTextSize(1, 1);
        printer.addFeedLine(0);
        printer.addText('________________________________________________');
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addFeedLine(2);
        printer.addTextAlign(printer.ALIGN_LEFT);
        getItemsList(commande, printer);
        printer.addFeedLine(1);
        printer.addText('------------------------------------------------');

        printer.addFeedLine(1);
        printer.addTextAlign(printer.ALIGN_CENTER);
        printer.addTextStyle(false, false, true, printer.COLOR_1);
        printer.addText('Remarque du client : ');
        printer.addFeedLine(2);
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addText(commande.customer_notes || "");

        printer.addFeedLine(2);
        printer.addText('________________________________________________');
        printer.addFeedLine(2);
        //getTotalPaid(commande, printer);
        printer.addText('------------------------------------------------');
        printer.addFeedLine(1);
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addText('Merci pour votre commande à mon enseigne');

        printer.addFeedLine(3);
        printer.addCut(printer.CUT_FEED);
        printer.send();
        result = true;
        setLoading(false);
    }

}

module.exports = {
    printCommande
}