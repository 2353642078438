import { Box, Text } from "grommet";
import { Print } from "grommet-icons";
import React, { useEffect, useState } from "react";
import "react-block-ui/style.css";
import { ORDER_STATUS } from "../../services/order_service";
import { printCommande } from "../../services/print_service";
import { getPrinterIp } from "../../services/storage";
import { COLOR, FONT_SIZE, getTitle } from "../../services/utils";
import FullSeperator from "../elements/full_seperator";

const bgColor = "#e6f3fe";
const frontColor = "#033076";

const validatedColor = "#72bb53";
const inValidatedColor = "#e61610";

const getDisplayedValue = (value, euro, ignoreZero) => {
  console.log("val: ", value);
  let floatValue = value?.replace(" EUR", "") || 0;
  let displayedValue = parseFloat(floatValue) * 0.8;
  if (displayedValue === 0 && ignoreZero) return "";
  return !euro
    ? displayedValue.toFixed(2).replace(".", ",")
    : displayedValue.toFixed(2).replace(".", ",") + " €";
};

const is25Percentage = (order) => {
  let discounts = order.discounts;
  let totalPriceStr = (order.total || "").replace(" EUR", "");
  let totalPrice = parseFloat(totalPriceStr) || 0;
  if (!discounts || !discounts.length) {
    return false;
  }
  let totalDiscount = discounts
    .map((p) => p.price_off)
    .filter((p) => p)
    .map((p) => parseFloat(p.replace(" EUR", "")) || 0)
    .reduce((a, s) => a + s, 0);
  totalDiscount = parseFloat(totalDiscount.toFixed(2));
  let percent = totalDiscount / totalPrice;
  return percent.toFixed(2) === "0.25";
};

const getTotalCommande = (commande) => {
  if (!commande.discounts) {
    commande.discounts = [];
  }
  let totalDiscount = commande.discounts
    .map((p) => p.price_off)
    .filter((p) => p)
    .map((p) => parseFloat(p.replace(" EUR", "")) || 0)
    .reduce((a, s) => a + s, 0);

  let totalPrice =
    parseFloat(commande.total.replace(" EUR", "")) + totalDiscount;
  totalPrice = totalPrice.toFixed(2) + " EUR";
  return is25Percentage(commande) ? totalPrice : commande.total;
};

const isCompleted = (commande) => commande.status === ORDER_STATUS.COMPLETED;

const ProcessingCommande = ({
  commande,
  onValidate,
  setLoading,
  showMessage,
}) => {
  const [isValidable, setIsValidable] = useState(isCompleted(commande));

  const [checked, setChecked] = useState(commande.items || []);

  useEffect(() => {
    setChecked(commande.items);
    setIsValidable(isCompleted(commande));
  }, commande);

  const onCheckedChange = (id) => {
    if (isCompleted(commande)) return;
    let item = checked.find((i) => i.id === id);
    item.clicked = item.clicked ? false : true;

    setIsValidable(!checked.find((i) => !i.clicked));
    setChecked(checked.map((i) => i));
  };

  const getBackgroundColor = (id) => {
    let result = isItemClicked(id) ? validatedColor : bgColor;
    return result;
  };

  const getTextColor = (id) => {
    return isItemClicked(id) ? "#fff" : frontColor;
  };

  const isItemClicked = (id) => checked.find((i) => i.id === id)?.clicked;

  const getQuantityColor = (quantity, id) =>
    parseFloat(quantity) > 1.0 ? COLOR.red : getTextColor(id);

  const getDiscountValue = (commande) => {
    if (!commande.discounts) {
      commande.discounts = [];
    }
    if (commande.discounts.length === 0) return "";
    return (
      commande.discounts
        .map((i) => i.price_off.replace(" EUR", ""))
        .map((v) => parseFloat(v))
        .reduce((a, s) => a + s, 0) + ""
    );
  };

  return (
    <Box
      width={"full"}
      background={COLOR.light_blue}
      round={{ size: "medium" }}
      style={{ height: 650, padding: 5, minHeight: "auto" }}
    >
      <Box
        width={"full"}
        style={{
          height: 950,
          display: "flex",
          flexDirection: "column",
          padding: 20,
        }}
        gap="small"
      >
        {getPrinterIp() && (
          <Print
            onClick={() =>
              printCommande(commande, (res) => setLoading(res), showMessage)
            }
          />
        )}
        <Text
          textAlign="center"
          style={{ fontWeight: "bold", fontSize: 30 }}
          color={COLOR.dark_blue}
        >
          {getTitle(commande)}
        </Text>
        {commande.customer_notes && <Text
          textAlign="center"
          style={{ fontSize: FONT_SIZE.xsmall, fontStyle: 'italic' }}
          color={COLOR.dark_blue}
        >
          <b>Note:</b> {commande.customer_notes}
        </Text>}
        <Box
          background={COLOR.light_blue}
          style={{ overflowY: "auto", height: 600 }}
        >
          {(commande.items || []).map((item, index) => (
            <Box
              key={index}
              style={{
                padding: 10,
                cursor: "pointer",
                background: getBackgroundColor(item.id),
                minHeight: "auto",
              }}
              onClick={() => onCheckedChange(item.id)}
              gap={5}
            >
              <Box
                direction="row"
                margin={5}
                style={{ minHeight: "auto" }}
                justify="between"
              >
                <Box direction="row" style={{ minHeight: "auto" }}>
                  <Text
                    color={getQuantityColor(item.quantity, item.id)}
                    style={{ fontSize: FONT_SIZE.medium, fontWeight: "bold" }}
                  >
                    X{item.quantity}
                  </Text>
                  <div style={{ width: 40 }} />
                  <Text
                    style={{
                      fontSize:
                        item.product_name.length > 25
                          ? FONT_SIZE.small
                          : FONT_SIZE.medium,
                      color: getTextColor(item.id),
                      fontWeight: getDisplayedValue(item.price) ? 'bold' : 'normal'
                    }}
                  >
                    {item.product_name?.toUpperCase()}
                  </Text>
                </Box>
                <Box justify="center">
                  <Text
                    style={{
                      fontSize: FONT_SIZE.medium,
                      color: getTextColor(item.id),
                      fontWeight: getDisplayedValue(item.price) ? 'bold' : 'normal'
                    }}
                  >
                    {getDisplayedValue(item.total)}
                  </Text>
                </Box>
              </Box>
              {item.options?.map((option, opi) => (
                <Box key={opi} style={{ minHeight: "auto" }}>
                  <Box
                    direction="row"
                    margin={5}
                    justify="between"
                    style={{
                      minHeight: "auto",
                    }}
                  >
                    <Box direction="row" align="center">
                      <div style={{ width: 40 }} />
                      <Text
                        style={{
                          fontSize: FONT_SIZE.small,
                          color: getQuantityColor(option.quantity, item.id),
                          fontWeight: "bold",
                        }}
                      >
                        X{option.quantity}
                      </Text>
                      <div style={{ width: 10 }} />
                      <Text
                        style={{
                          fontSize: FONT_SIZE.small,
                          color: getTextColor(item.id),
                          fontWeight: getDisplayedValue(option.price, false, true) ? 'bold' : 'normal'
                        }}
                      >
                        {option.name?.toUpperCase()}
                      </Text>
                    </Box>
                    <Box align="center">
                      <Text
                        style={{
                          fontSize: FONT_SIZE.small,
                          color: getTextColor(item.id),
                          fontWeight: getDisplayedValue(option.price, false, true) ? 'bold' : 'normal'
                        }}
                      >
                        {getDisplayedValue(option.price, false, true)}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              ))}
              {index === commande.items.length - 1 ? null : isItemClicked(
                  item.id
                ) ? (
                <FullSeperator color="#fff" />
              ) : (
                <FullSeperator />
              )}
            </Box>
          ))}

          {/* {(commande.items || []).map((item, index) => (
            <div
              key={index}
              style={{
                padding: 10,
                cursor: "pointer",
                background: getBackgroundColor(item.id),
                minHeight: "auto",
              }}
              onClick={() => onCheckedChange(item.id)}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  margin: 5,
                  minHeight: "auto",
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: FONT_SIZE.medium,
                    color: getQuantityColor(item.quantity, item.id),
                  }}
                >
                  X{item.quantity}
                </span>
                <div style={{ width: 40 }} />
                <span
                  style={{
                    fontSize:
                      item.product_name.length > 25
                        ? FONT_SIZE.small
                        : FONT_SIZE.medium,
                    color: getTextColor(item.id),
                  }}
                >
                  {item.product_name?.toUpperCase()}
                </span>

                <div
                  style={{ position: "absolute", right: 60, minHeight: "auto" }}
                >
                  <span
                    style={{
                      fontSize:
                        item.product_name.length > 25
                          ? FONT_SIZE.small
                          : FONT_SIZE.medium,
                      color: getTextColor(item.id),
                    }}
                  >
                    {getDisplayedValue(item.subtotal)}
                  </span>
                </div>
              </div>
              {item.options?.map((option, opi) => (
                <div key={opi} style={{ minHeight: "auto" }}>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      margin: 5,
                      minHeight: "auto",
                    }}
                  >
                    <div style={{ width: 40 }} />
                    <span
                      style={{
                        fontSize: FONT_SIZE.small,
                        color: getQuantityColor(option.quantity, item.id),
                        fontWeight: "bold",
                      }}
                    >
                      X{option.quantity}
                    </span>
                    <div style={{ width: 10 }} />
                    <span
                      style={{
                        fontSize: FONT_SIZE.small,
                        color: getTextColor(item.id),
                      }}
                    >
                      {option.name?.toUpperCase()}
                    </span>
                    <div style={{ position: "absolute", right: 60 }}>
                      <span
                        style={{
                          fontSize: FONT_SIZE.small,
                          color: getTextColor(item.id),
                        }}
                      >
                        {getDisplayedValue(option.price, false, true)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {index === commande.items.length - 1 ? null : isItemClicked(
                  item.id
                ) ? (
                <FullSeperator color="#fff" />
              ) : (
                <FullSeperator />
              )}
            </div>
          ))} */}
          {getDiscountValue(commande) && !is25Percentage(commande) && (
            <Box
              width={"full"}
              style={{ height: 50 }}
              justify="between"
              direction="row"
            >
              <Text></Text>
              <Text style={{ fontSize: FONT_SIZE.small, color: "red" }}>
                PROMO: - {getDisplayedValue(getDiscountValue(commande), true)}
              </Text>
            </Box>
          )}
          <Box width={"full"} direction="row" justify="between">
            <Text></Text>
            <Text style={{ fontSize: FONT_SIZE.medium, color: frontColor }}>
              TOTAL: {getDisplayedValue(getTotalCommande(commande), true)}
            </Text>
          </Box>
        </Box>

        {!isCompleted(commande) ? (
          <Box
            background={isValidable ? validatedColor : inValidatedColor}
            pad="medium"
            style={{ boxShadow: "none", minHeight: "auto" }}
            round={{ size: "medium" }}
            onClick={() => {
              if (isValidable) {
                onValidate(commande);
              }
            }}
          >
            <Text
              textAlign="center"
              style={{ fontWeight: "bold", fontSize: 35 }}
              color="#fff"
            >
              {"Préparation terminée"}
            </Text>
          </Box>
        ) : (
          <Box justify="center" align="center" gap="small">
            <div style={{ height: 20 }} />
            <Text
              size="xlarge"
              style={{ fontWeight: "bold" }}
              color={COLOR.dark_blue}
            >
              En attente du livreur
            </Text>
            <ul style={{ marginBlock: 0 }}>
              <li
                style={{
                  color: COLOR.dark_blue,
                  fontSize: FONT_SIZE.xsmall,
                  fontStyle: "italic",
                }}
              >
                Retard: les livreurs sont parfois très occupés, il peuvent
                prendre du temps à venir.
              </li>
              <li
                style={{
                  color: COLOR.dark_blue,
                  fontSize: FONT_SIZE.xsmall,
                  fontStyle: "italic",
                }}
              >
                Absent: le livreur ne vient pas, la commande est automatiquement
                payée par la plateforme.
              </li>
            </ul>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProcessingCommande;
