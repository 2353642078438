
import {
    Box,
    Layer, Text
} from "grommet";
import { Close } from "grommet-icons";
import React from "react";
import "react-toastify/dist/ReactToastify.css";

import { COLOR, FONT_SIZE } from "../../services/utils";
import StandardText from "../elements/standard_text";

const HolidayPopup = ({ onClose }) => (
    <Layer style={{ background: 'transparent' }}>
        <Box width={'xlarge'} background={COLOR.light_blue} pad={'small'} gap='medium'
            round={{ size: 'medium' }}
            style={{ height: 600, minHeight: 'auto' }}
        >
            <Box style={{ position: 'absolute', left: 10, top: 10 }} onClick={onClose} >
                <Close color={COLOR.dark_blue} size='large' />
            </Box>
            <Box width={'full'} justify="center" align="center" style={{ minHeight: 'auto' }}
                margin={{ bottom: 'large' }}>
                <StandardText label={'Contactez-nous'} color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.xlarge, fontWeight: 'bold' }} />
            </Box>
            <br />
            <Box width={'full'} align="center" style={{ minHeight: 'auto', lineHeight: 'normal' }}>
                <Box width={'full'}>
                    <StandardText
                        label={"Vous partez en vacances ?"}
                        bold
                        color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.large, fontWeight: 'bold' }}
                    /><br />
                    <StandardText
                        label={"Tenez nous au courant, communiquez nous vos horaires de fermetures et ouvertures, ainsi que le motif : "}
                        bold
                        color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.large, fontWeight: 'bold', lineHeight: 1.3 }}
                    />
                </Box>
                <br />
                <ul style={{ fontWeight: 'bold', fontSize: FONT_SIZE.large }}>
                    <li>
                        <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.large, lineHeight: 'normal' }}>
                            Téléphone: 09 85 40 53 14
                            <span style={{ fontWeight: 'normal' }}>&nbsp; de 09h à 18h du lundi au vendredi.</span>
                        </Text>
                    </li>
                    <li>
                        <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.large, lineHeight: 'normal' }}>
                            Mail: partenaire@meor.fr
                            <span style={{ fontWeight: 'normal' }}>&nbsp; 24/24-7/7J</span>
                        </Text>
                    </li>
                </ul>

            </Box>
        </Box>
    </Layer>
)
export default HolidayPopup;