import {
    Box, Card, Text, TextInput
} from "grommet";
import { Checkmark, Close } from "grommet-icons";
import React, { useState } from "react";
import { clearToken } from "../../services/storage_service"

const bgColor = '#e6f3fe';
const ADMIN_PASS = 'xj!35*'

export default function AdminPopup({ onClose }) {

    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);

    return (
        <Box width={'large'} round={{ size: 'medium' }} background='#fff'>
            <Box justify="end" pad={'xsmall'} width='medium' onClick={onClose}>
                <Close color="brand" />
            </Box>
            <Box align="center" justify="center" direction="row" pad={'medium'} gap='medium'>
                <TextInput value={password} type='password' onChange={event => setPassword(event.target.value)}
                    placeholder='Votre mot de passe admin'
                    size="xxlarge"
                />
                <Card pad={'small'} background='#033076' onClick={() => {
                    if (password !== ADMIN_PASS) {
                        setError(true);
                        return;
                    }
                    clearToken();
                    window.location.href = '/';
                }}>
                    <Checkmark />
                </Card>
            </Box>
            <Box align="center" justify="center">
                {
                    error && <Text size="small" color={'red'}>Mauvais mot de passe</Text>
                }
            </Box>
        </Box>
    )
}

export const AdminAccessButton = ({onOpen}) => {
    return (
        <Box style={{ position: 'absolute', bottom: 10, left: 10 }}>
            <Card
                border={{ color: bgColor, size: 'small' }}
                pad='medium'
                style={{ boxShadow: 'none' }}
                onClick={onOpen}>
                <Text color={'#000'} style={{ fontSize: 40, color: '#fff' }}>Admin</Text>
            </Card>
        </Box>
    )
}