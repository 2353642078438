import { navigate } from "gatsby";
import { Box, Layer } from "grommet";
import React from "react";
import moment from "moment";
import Layout from "../components/elements/layout";
import authService from "../services/auth_service";
import storageService from "../services/storage_service";
import { printCommande } from "../services/print_service";
import partnerService from "../services/partner_service";
import orderService, { ORDER_STATUS } from "../services/order_service";
import SignIn from "../components/sign_in";
import config from "../config/config.json";
import NewCommandSignal from "../components/processing_commande/new_command_signal";
import HomePage from "../components/home_page/index_v2";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OfflinePage from "../components/offline_page";
import HistoryCommande from "../components/history_commandes";
import SettingsPage from "../components/settings_page";
import StandardText from "../components/elements/standard_text";
import { COLOR, FONT_SIZE } from "../services/utils";

const showMessage = (message, type) => {
  toast.error(message, type || "info");
};
const EVENTS = {
  nothing: "nothing",
  hasNewCommande: "hasNewCommande",
  signIn: "signIn",
  history: "history",
  processing: "processing",
  settings: "settings",
  offline: "offline",
};

const containsCommande = (list, commande) =>
  list.map((c) => c.id).includes(commande.id);

const mergeWithOrders = (olds, news) => {
  const result = [];
  for (const old of olds) {
    const from = news.find((n) => n.id === old.id);
    if (from) {
      result.push({ ...old, ...from });
    } else {
      result.push(old);
    }
  }
  return result;
};

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: EVENTS.signIn,
      terminatedCommandes: [],
      processingCommandes: [],
      newCommandes: [],
      selectedCommande: undefined,
      commande_to_reject: undefined,
      waitingRejectedTime: undefined,
      currentTime: moment().format('HH:mm')
    };
  }

  componentDidMount() {
    this.checkVersion();
    this.checkConnected();
  }
  checkVersion = () => {
    const currentVersion = storageService.getVersion();
    if (!currentVersion) {
      navigate("/update");
      return;
    }
    authService.getVersion().then((res) => {
      if (res.data.version !== currentVersion && res.data.force_update) {
        navigate("/update");
      }
    });
  };
  checkConnected = () => {
    if (!storageService.getUser()) {
      navigate("/login");
    }
  };

  playAlert = () => {
    document.getElementById("audioPlayer").play();
  };

  init = () => {
    this.getLatestCommandes();
    this.checkCurrentStatus();
    this.globalVerify();
    this.localVerify();
  };
  
  getLatestCommandes = () => {
    orderService
      .getLatestCommandes()
      .then((res) => {
        const orders = res.data.data;
        let latestCommandes = orders.filter((c) => c.status !== "NEW");
        if (latestCommandes.length > 0) {
          this.setState({
            terminatedCommandes: latestCommandes.filter(
              (s) => s.status === "COMPLETED"
            ),
            processingCommandes: latestCommandes.filter(
              (s) => s.status === "ACCEPTED"
            ),
          });
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.status === 401) {
          //navigate("/login");
        }
      });
  }

  checkCurrentStatus = () => {
    const partner = storageService.getPartner() || {};
    const status = partner.status;
    if (status && status !== "OPEN") {
      storageService.setDisponibility(false);
      this.setState({ event: EVENTS.settings });
    } else {
      storageService.setDisponibility(true);
      if (partnerService.isRestoClose()) {
        this.setState({ event: EVENTS.offline });
      } else {
        this.setState({ event: EVENTS.nothing });
      }
    }
  };

  globalVerify = () => {
    if (
      storageService.isDispo() &&
      (this.state.event === EVENTS.nothing ||
        this.state.event === EVENTS.history)
    ) {
      this.fetchNewOrders();
    }
    this.getPartnerInfo();

    setTimeout(() => this.globalVerify(), config.GLOBAL_INTERVAL);
  };

  getPartnerInfo = () => {
    partnerService
      .getPartnerInfo()
      .then((pnRes) => {
        if (pnRes.data.data?.length) {
          const partner = pnRes.data.data[0];
          storageService.setPartner(partner);
          const status = partner.status;
          if (status && status !== "OPEN") {
            storageService.setDisponibility(false);
            this.setState({ event: EVENTS.settings });
          } else {
            storageService.setDisponibility(true);
            this.verifyCloseTime();
          }
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          storageService.clearAuth();
          navigate("/login");
        }
      });
  };

  verifyCloseTime = () => {
    if (partnerService.isRestoClose()) {
      this.setState({ event: EVENTS.offline });
    } else {
      if (
        this.state.event === EVENTS.offline
      ) {
        this.setState({ event: EVENTS.nothing });
      }
    }
  };

  fetchNewOrders = () => {
    orderService
      .getNewCommandes()
      .then((res) => {
        const orders = res.data;
        let processingCommandes = this.state.processingCommandes;
        let terminatedCommandes = this.state.terminatedCommandes;
        let newCommandes = orders.filter(
          (c) =>
            !containsCommande(terminatedCommandes, c) &&
            !containsCommande(processingCommandes, c)
        );

        // updated value of terminatedcommandes + processingCommandes
        terminatedCommandes = mergeWithOrders(terminatedCommandes, orders);
        processingCommandes = mergeWithOrders(processingCommandes, orders);
        console.log(newCommandes, processingCommandes, terminatedCommandes);
        // check if new commandes has passed 15m, if any, send it to completed commandes
        let forcedTerminatedCommandes = newCommandes.filter(
          (c) => moment().diff(moment(c.placed_at), "minutes") > 15
        );
        for (let c of forcedTerminatedCommandes) {
          orderService.changeStatus(c, ORDER_STATUS.COMPLETED);
          c.status = ORDER_STATUS.COMPLETED;
          if (!terminatedCommandes.map((c) => c.id).includes(c.id)) {
            terminatedCommandes.push(c);
          }
        }

        newCommandes = newCommandes.filter(
          (c) => !terminatedCommandes.map((tc) => tc.id).includes(c.id)
        );

        this.setState({
          newCommandes,
          terminatedCommandes,
          processingCommandes,
        });
        if (newCommandes.length > 0) {
          console.log("found new commandes", newCommandes.length);
        }
        if (storageService.getAutoPrint()) {
          for (const newC of newCommandes) {
            printCommande(
              newC,
              () => {},
              () => {}
            );
          }
        }
      })
      .catch((err) => {});
  };

  localVerify = () => {
    if (
      this.state.event === EVENTS.history ||
      this.state.event === EVENTS.nothing
    ) {
      if (this.state.newCommandes.length > 0) {
        this.newCommandeHandler();
      }
    }

    // verify if order should be rejected
    this.verifyRejectableOrder();

    setTimeout(() => this.localVerify(), config.LOCAL_INTERVAL);
    this.setState({currentTime: moment().format('HH:mm')})
  };

  newCommandeHandler = () => {
    let newCommandes = this.state.newCommandes;
    if (!newCommandes.length) return;
    let selectedCommande = newCommandes[newCommandes.length - 1];
    this.setState({
      event: EVENTS.hasNewCommande,
      commande_to_reject: selectedCommande,
      waitingRejectedTime: 1,
    });
  };

  newCommandeProcessingHandler = async () => {
    let newCommandes = this.state.newCommandes;
    if (!newCommandes.length) return;
    let selectedCommande = newCommandes.pop();
    let processingCommandes = this.state.processingCommandes;
    processingCommandes.push(selectedCommande);
    orderService.changeStatus(selectedCommande, ORDER_STATUS.ACCEPTED);
    selectedCommande.status = ORDER_STATUS.ACCEPTED;
    selectedCommande.startProcessingTime = new Date();
    processingCommandes.sort((c1, c2) =>
      c1.startProcessingTime > c2.startProcessingTime ? -1 : 1
    );
    this.setState({
      event: EVENTS.nothing,
      selectedCommande,
      newCommandes,
      processingCommandes: processingCommandes.map((c) => c),
      commande_to_reject: undefined,
      waitingRejectedTime: undefined,
    });
  };

  verifyRejectableOrder = () => {
    const commandeToReject = this.state.commande_to_reject;
    if (!commandeToReject) return;
    // this function is called every 5s
    const waitingRejectedTime = this.state.waitingRejectedTime;
    if (waitingRejectedTime > 6 * 30) {
      // reject order
      orderService.changeStatus(commandeToReject, "REJECTED");
      this.setState({
        event: EVENTS.nothing,
        commande_to_reject: undefined,
        waitingRejectedTime: undefined,
      });
    } else {
      this.setState({ waitingRejectedTime: waitingRejectedTime + 5 });
    }
  };

  completeCommande = (command) => {
    let terminatedCommandes = this.state.terminatedCommandes;
    orderService.changeStatus(command, ORDER_STATUS.COMPLETED);
    command.status = ORDER_STATUS.COMPLETED;
    terminatedCommandes.push(command);
    let processingCommandes = this.state.processingCommandes;
    processingCommandes = processingCommandes.filter(
      (c) => c.id !== command.id
    );
    this.setState({
      terminatedCommandes: terminatedCommandes.map((c) => c),
      processingCommandes,
    });
  };

  changeDisableInventory = () => {
    this.setState({ disable_inventory: true });
    setTimeout(() => {
      this.setState({ disable_inventory: false });
    }, 60000);
  };

  render() {
    return (
      <Layout style={{ justifyContent: "center", alignContent: "center" }}>
        <Box width="full" justify="center" align="center" alignSelf="center">
          <Box width="full">
            {this.state.event === EVENTS.signIn && (
              <SignIn
                toHomePage={() => {
                  this.playAlert();
                  this.init();
                }}
              />
            )}
            {this.state.event === EVENTS.hasNewCommande && (
              <NewCommandSignal
                tap={() => this.newCommandeProcessingHandler()}
                playAlert={this.playAlert}
              />
            )}

            {this.state.event === EVENTS.nothing && (
              <HomePage
                toHistoryPage={() => this.setState({ event: EVENTS.history })}
                toSettingPage={() => this.setState({ event: EVENTS.settings })}
                processingCommandes={this.state.processingCommandes}
                terminatedCommandes={this.state.terminatedCommandes}
                processingCommande={this.state.selectedCommande}
                validateCommande={this.completeCommande}
                showMessage={showMessage}
                currentTime={this.state.currentTime}
              />
            )}

            {this.state.event === EVENTS.history && (
              <HistoryCommande
                onLoad={(historyCommandes) =>
                  this.setState({
                    lastLoadedHistory: new Date(),
                    historyCommandes,
                  })
                }
                lastLoadedHistory={this.state.lastLoadedHistory}
                lastCommandes={this.state.historyCommandes}
                onClose={() =>
                  this.setState({
                    event: partnerService.isRestoClose()
                      ? EVENTS.offline
                      : EVENTS.nothing,
                  })
                }
              />
            )}
            {this.state.event === EVENTS.settings && (
              <SettingsPage
                changeDisableInventory={this.changeDisableInventory}
                disable_inventory={this.state.disable_inventory}
                onClose={() => {
                  this.setState({
                    event: partnerService.isRestoClose()
                      ? EVENTS.offline
                      : EVENTS.nothing,
                  });
                }}
              />
            )}

            {this.state.event === EVENTS.offline && (
              <OfflinePage
                onClose={() => this.setState({ event: EVENTS.nothing })}
                toHistoryPage={() => this.setState({ event: EVENTS.history })}
                toSettingPage={() => this.setState({ event: EVENTS.settings })}
                currentTime={this.state.currentTime}
              />
            )}
          </Box>
          {storageService.getVersion() && (
            <Box style={{ position: "fixed", bottom: 10, right: 10 }}>
              <StandardText
                label={`Build ${storageService.getVersion()}`}
                size={FONT_SIZE.medium}
                color={COLOR.dark_blue}
              />
            </Box>
          )}
          
          <Box style={{ display: "none" }}>
            <audio
              id="audioPlayer"
              src={
                "https://storage.googleapis.com/trustiway-bo-dev.appspot.com/alert_c5d02b629d/alert_c5d02b629d.mp3"
              }
            />
          </Box>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Box>
      </Layout>
    );
  }
}

export default IndexPage;
