import { Box, Layer, Text } from "grommet";
import { Close } from "grommet-icons";
import React from "react";
import { COLOR, FONT_SIZE } from "../../services/utils";
const isDeliveroo = (c) => c.platform === "DELIVEROO";

const getCategory = (order) => {
  if (order.platform === "UBER_EAT") {
    return order.extra_field?.meta?.store?.name || order.catalog_name;
  }
  return order.catalog_identifier || order.catalog_name;
};

export default function ({ order, setProblem }) {
  return (
    <Layer style={{ background: "transparent" }}>
      <Box
        width={"large"}
        background={COLOR.dark_blue}
        justify="center"
        align="center"
        pad={"small"}
        gap="medium"
        round={{ size: "medium" }}
        style={{ height: 500 }}
      >
        <Box
          style={{ position: "absolute", left: 10, top: 10 }}
          onClick={() => setProblem(undefined)}
        >
          <Close color={COLOR.light_blue} size="large" />
        </Box>
        <Text
          color={COLOR.light_blue}
          textAlign="center"
          style={{ fontSize: FONT_SIZE.large }}
        >
          Numéro assistance: <br />
          <br />
          {isDeliveroo(order) ? "09 77 55 03 31" : "08 05 08 42 86"}
        </Text>
        <br />
        <Text
          color={COLOR.light_blue}
          textAlign="center"
          style={{ fontSize: FONT_SIZE.large }}
        >
          Code restaurant:
          <br />
          <br /> {getCategory(order)}
        </Text>
        <br />
        <Text
          color={COLOR.light_blue}
          textAlign="center"
          style={{ fontSize: FONT_SIZE.large }}
        >
          Numéro de commande: <br />
          <br /> {order?.collection_code}
        </Text>
      </Box>
    </Layer>
  );
}
