import {
    Box, Layer, Text
} from "grommet";
import { Close } from 'grommet-icons';
import React from "react";
import { COLOR, FONT_SIZE } from "../../services/utils";
const isDeliveroo = c => c.platform === "DELIVEROO";

const getRider = c => {
    console.log('riders commandes: ', c);
    if(c.riders?.length) {
        const rider = c.riders[0];
        return {
            name: rider.full_name || rider.first_name,
            expected_pickup_at: rider.estimated_arrival_time || rider.estimated_pickup_time,
            code: rider.bridge_code || rider.phone,
            number: rider.bridge_number || rider.phone_code
        }
    }
}
const getRiderTime = c => {
    const rider = getRider(c);
    return rider ? rider.expected_pickup_at : c.expected_pickup_at
}

export default function ({order, setRider}) {
    return (
        <Layer style={{ background: 'transparent' }}>
            <Box width={'large'} background={COLOR.dark_blue} justify='center' align="center" pad={'small'} gap='medium'
                round={{ size: 'medium' }}
                style={{ height: 500 }}
            >
                <Box style={{ position: 'absolute', left: 10, top: 10 }} onClick={() => setRider(undefined)} >
                    <Close color={COLOR.light_blue} size='large' />
                </Box>
                <Text color={COLOR.light_blue} textAlign='center' style={{ fontSize: FONT_SIZE.large }}>
                    {getRider(order)?.name?.toUpperCase() || 'Non assigné'}
                </Text>
                <br />
                <Text color={COLOR.light_blue} textAlign='center' style={{ fontSize: FONT_SIZE.large }}>
                    Numéro :<br /><br /> {getRider(order)?.number || 'Non assigné'}
                </Text>
                <br />
                <Text color={COLOR.light_blue} textAlign='center' style={{ fontSize: FONT_SIZE.large }}>
                    Code: <br /><br />{getRider(order)?.code || 'Non assigné'}
                </Text>
            </Box>
        </Layer>
    )
}