import {
    Box, Text
} from "grommet";
import React from "react";
import { CaretDownFill, CaretRightFill } from 'grommet-icons';
import { useState } from "react";
import { FONT_SIZE, getTitle } from "../../../services/utils";
import moment from "moment";

const bgColor = '#e6f3fe';
const frontColor = '#033076';
const getTime = date => moment(date).locale('fr').format('HH:mm');

const CollapsibleCommande = ({ commande }) => {

    const [expanded, setExpanded] = useState(false);

    return (
        <Box width={'full'} pad='medium' justify="center" align="center" onClick={() => setExpanded(!expanded)} style={{ boxShadow: 'none' }}>
            <Box direction="row" align="center" justify="between" gap="medium" width={'full'} pad='small'>
                <Box direction="row" align="center" justify="center" gap="small">
                    {!expanded ?
                        <CaretRightFill color={'#e6f3fe'} size="xlarge" /> :
                        <CaretDownFill fill="#c8c8c8" color="#e6f3fe" size="xlarge" />
                    }
                    <Text style={{ fontSize: FONT_SIZE.xlarge }} color={frontColor}>{getTitle(commande)}</Text>
                </Box>
                <Box direction="row" align="center" justify="center" gap="small">
                    <Text style={{ fontSize: FONT_SIZE.large }} color={frontColor}> {getTime(commande.placed_at)}</Text>
                </Box>
            </Box>

            {expanded &&
                <Box width={'full'} pad='xsmall' justify="center" align="center" gap="medium">
                    {
                        commande.items.map((item, index) => <Item key={index} item={item} color={'#033076'} />)
                    }
                </Box>
            }
        </Box>
    )
}

const Item = ({ item }) => {
    return (
        <Box align="center" justify="between" width={'full'}>
            <Box direction="row" align="center" justify="between" gap="large" width={'full'}>
                <Box direction="row" gap="large" width={'full'}>
                    <Box style={{ width: '10%' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: FONT_SIZE.large }} color={'#033076'}>X{item.quantity}</Text>
                    </Box>
                    <Box>
                        <Text style={{ fontWeight: 'bold', fontSize: FONT_SIZE.large }} color={'#033076'}>{item.product_name}</Text>
                    </Box>
                </Box>
                <Box direction="row" align="center" justify="center" gap="small">
                </Box>
            </Box>
            <Box style={{height: 20}}/>
            <Box align="start" width={'full'} gap='small'>
                {
                    item.options?.map((option, index) => (
                        <Box direction="row" align="center" justify="between" gap="medium" width={'full'} key={index}>
                            <Box direction="row" align="center" justify="center" gap="medium" key={index} width={'full'}>
                                <Box style={{ width: '10%' }} justify="center" align="center">
                                </Box>
                                <Box direction="row" align="center" gap="medium" width={'full'}>
                                    <Box style={{ width: '10%' }} justify="center" align="center">
                                        <Text style={{ fontSize: FONT_SIZE.medium }} color={'#033076'}>X{option.quantity}</Text>
                                    </Box>
                                    <Text style={{ fontSize: FONT_SIZE.medium }} color={'#033076'}>{option.name}</Text>
                                </Box>
                            </Box>
                            <Box direction="row" align="center" justify="center" gap="small">
                            </Box>
                        </Box>
                    ))
                }
            </Box>

        </Box>
    )
}

export {
    Item
};
export default CollapsibleCommande;