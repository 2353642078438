
import {
    Box, Text
} from "grommet";
import { FormPreviousLink } from 'grommet-icons';
import moment from "moment";
import 'moment/locale/fr' 
import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";
import { getAllCommandes } from "../../services/order_service";
import { FONT_SIZE } from "../../services/utils";
import CollapsibleCommande from "./collapsible_commande";
import 'react-block-ui/style.css';

const groupByDate = commandes => {
    const map = new Map();
    commandes.forEach(commande => {
        let date = moment(commande.placed_at).format('YYYY-MM-DD');//;
        //if(!map[date]) map[date] = [];
        if(!map.get(date)) {
            map.set(date, []);
        }
        map.get(date).push(commande);
    })
    let groupBy = Array.from(map, ([date, c]) => ({date, commandes: c}));
    groupBy.sort((g1, g2) => g1.date > g2.date ? -1 : 1);
    return groupBy;
}

const toFullDate = date => moment(date).locale('fr').format('D MMMM YYYY');

const frontColor = '#033076';


const HistoryCommande = ({ onClose }) => {

    const [commandes, setCommandes] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        getAllCommandes()
        .then(res => {
            setLoading(false);
            setCommandes(res.data)
        }).catch(e => setLoading(false));
    }, [])

    return (
        <Box width={'full'}>
            <BlockUi tag="div" blocking={loading}>
                <Box pad={'small'} background='#e6f3fe' align="center" width={'full'} justify='center'>
                    <Box pad={'small'}>
                        <Text style={{ fontSize: 60 }} color="#033076"> Dernière commandes</Text>
                    </Box>
                    <Box style={{ position: 'absolute', left: 10, boxShadow: 'none' }} onClick={onClose} width={'full'} justify="center">
                        <FormPreviousLink size="xlarge" color={frontColor} />
                    </Box>
                </Box>

                {
                    groupByDate(commandes).map((group, index) => (<GroupCommande group={group} key={index} />))
                }
            </BlockUi>
        </Box>
    )
}

const GroupCommande = ({group}) => {

    return(
        <Box width={'full'} justify="center" align="center">
            <Box width={'full'} justify="center" align="center" background="#d4e3fc" pad={'medium'}>
                <Text style={{fontSize: FONT_SIZE.xlarge}} color={'#033076'} textAlign='center'>{toFullDate(group.date)}</Text>
            </Box>    
            <Box justify="center" align="center" width={'full'}>
                {
                    group.commandes.map((c, index) => (<CollapsibleCommande commande={c} key={index}/>))
                }
            </Box>
        </Box>
    )
}

export default HistoryCommande;