import {
    Box, Layer, Text
} from "grommet";
import { Close } from 'grommet-icons';
import React from "react";
import { COLOR, FONT_SIZE } from "../../services/utils";

const HelpPopup = ({ setHelp }) => (

    <Layer style={{ background: 'transparent' }}>
        <Box width={'xlarge'} background={COLOR.foreground_disabled} justify='center' align="center" pad={'medium'} gap='medium'
            round={{ size: 'medium' }}
        >
            <Box style={{ position: 'absolute', left: 10, top: 10 }} onClick={() => setHelp(false)}
                round={{ size: 'medium' }} border={{ size: 'small', color: COLOR.dark_blue }}
                pad='small'
            >
                <Close color={COLOR.background_disabled} size='small' />
            </Box>
            <Box width={'full'} background={COLOR.light_blue} round={{ size: 'medium' }} justify="center" align="center"
                style={{ padding: 10 }}
            >
                <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.medium }} >COMMANDES EN COURS</Text>
            </Box>
            <Box style={{ padding: 5 }} width='full' direction="row" align="center" gap="xsmall">
                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>
                    {` Cliquez sur `} </Text>
                <Box style={{ boxShadow: 'none', paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5 }} round={{ size: 'medium' }}
                    background={COLOR.foreground_disabled}
                    border={{ color: COLOR.dark_blue, size: 'medium' }}
                >
                    <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold' }}>Problèmes ?</Text>
                </Box>
                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>
                    depuis la commande concernée pour <b>contacter le support dédié.</b></Text>
            </Box>
            <Box width={'full'} background={COLOR.light_blue} round={{ size: 'medium' }} justify="center" align="center"
                style={{ padding: 10 }}
            >
                <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.medium }} >QUESTIONS</Text>
            </Box>
            <Box style={{ padding: 5 }} width='full' gap="medium">
                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium, fontWeight: 'bold' }}>
                    Du lundi au vendredi du 09h à 18h : <span style={{ fontSize: FONT_SIZE.large }}>0985405314</span>
                </Text>
            </Box>
            <Box width={'full'} background={COLOR.light_blue} round={{ size: 'medium' }} justify="center" align="center"
                style={{ padding: 10 }}
            >
                <Text color={COLOR.dark_blue} style={{ fontWeight: 'bold', fontSize: FONT_SIZE.medium }} >AUTRES</Text>
            </Box>
            <Box style={{ padding: 5 }} width='full' gap="medium">
                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>{` Remplir ce formulaire: `}
                    <a href="https://forms.gle/6R7x6LwYHPnH2UfG6" target={'_blank'} style={{ textDecoration: 'none', color: COLOR.dark_blue }}><b>https://forms.gle/6R7x6LwYHPnH2UfG6</b></a>
                </Text>
                <Text color={COLOR.dark_blue} style={{ fontSize: FONT_SIZE.medium }}>{` Contactez-nous via : `}
                    <b>partenaire@meor.fr</b>
                </Text>
            </Box>

        </Box>
    </Layer>
)

export default HelpPopup;