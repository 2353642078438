import { Box, Layer } from "grommet";
import { Play } from "grommet-icons";
import React, { useState } from "react";
import storage_service from "../../services/storage_service";
import { COLOR, FONT_SIZE, getSimpleTimeFR } from "../../services/utils";
import StandardText from "../elements/standard_text";
import AdminPopup, { AdminAccessButton } from "../sign_in/admin";

const PausedState = ({ onRefresh }) => {
  const [popup, setAdminPopup] = useState();

  return (
    <Layer>
      <Box
        width={"xlarge"}
        pad={"medium"}
        round={{ size: "medium" }}
        justify="center"
        align="center"
        style={{ minHeight: "auto" }}
      >
        <Box
          width={"full"}
          align="center"
          justify="between"
          style={{ height: 600, position: "relative" }}
          pad={"medium"}
        >
          <StandardText
            label={"En pause"}
            style={{ fontSize: FONT_SIZE.xlarge }}
            bold
            color={COLOR.dark_blue}
          />
          <Box justify="center" align="center">
            <StandardText
              label={"Les commandes sont momentanément interrompues"}
              bold
              style={{ fontSize: FONT_SIZE.large, fontWeight: "bold" }}
              color={COLOR.dark_blue}
            />
            <br />
            <br />
            <StandardText
              label={"L'établissement ouvrira automatiquement à " + getSimpleTimeFR(storage_service.getPartner().next_opened_at)}
              style={{ fontSize: FONT_SIZE.large }}
              color={COLOR.dark_blue}
            />
          </Box>
          <Box
            width={"medium"}
            pad={"medium"}
            justify="center"
            align="center"
            style={{ boxShadow: "none" }}
            round={{ size: "medium" }}
            direction="row"
            background={"#61D080"}
            gap="medium"
            onClick={onRefresh}
          >
            <Play color="#fff" size="large" />
            <StandardText
              label={"Reprendre"}
              color="#fff"
              style={{ fontSize: FONT_SIZE.large }}
            />
          </Box>
          <Box style={{ position: "absolute", bottom: 20, left: 30 }} width={'xsmall'} align="center">
            <AdminAccessButton onOpen={() => setAdminPopup(true)} />
          </Box>
          {popup && (
            <Layer style={{ background: "transparent" }}>
              <AdminPopup onClose={() => setAdminPopup(false)} />
            </Layer>
          )}
        </Box>
      </Box>
    </Layer>
  );
};

export default PausedState;
