
import {
    Box, Button, CheckBox, Layer, Text, TextInput
} from "grommet";
import { Close, Save } from "grommet-icons";
import React, { useState } from "react";
import BlockUi from "react-block-ui";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import partnerService from '../../services/partner_service';
import { getAutoPrint, getPrinterIp, setAutoPrint, setPrinterIp } from "../../services/storage";
import storageService from '../../services/storage_service';

import { COLOR, FONT_SIZE } from "../../services/utils";
import AdminPopup, { AdminAccessButton } from "../sign_in/admin";
import DisponibilitePage from "./disponibilite_v2";
import PausedState from "./pause";
import OpeningPopup from "../opening_popup";
import LeftMenu from "../menu";
import HolidayPopup from "../holiday_popup";
import { printCommande } from "../../services/print_service";
import 'react-block-ui/style.css';

const Header = ({ onClose, background }) => {

    return (
        <Box background={background || '#e6f3fe'} justify="between" align="center" width={'full'}
            direction='row'
        >
            <Box pad={'small'} style={{ left: 7, width: 10 }} >
            </Box>


            {storageService.isDispo() ?
                <Box pad={'small'} onClick={onClose} style={{ boxShadow: 'none' }}>
                    <Close size="large" color="#033076" />
                </Box> :
                <Box pad={'small'} style={{ boxShadow: 'none' }}>
                    <Close size="large" color="#e6f3fe" />
                </Box>
            }
        </Box>
    )
}

const PAGES = {
    disponibilite: 'disponibilite',
    printer: 'printer',
    settings: 'settings',
}

class SettingsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: PAGES.settings,
            loading: false,
            plats: [],
            closed: !storageService.isDispo(),
            isButtonDisabled: false,
            showRappel: true,
            adminPopup: false,
            showOpeningPopup: false,
            popupMode: false
        }
    }

    componentDidMount = () => {
    }

    viewDisponibilite = () => {
        if (!storageService.isDispo()) return;
        this.setState({ page: PAGES.disponibilite });
    }

    changeDisponibilite = async () => {

        let isDispo = storageService.isDispo();
        console.log(isDispo);
        let willOpen = !isDispo;

        this.setState({ loading: true });
        try {
            await partnerService.updateRestaurantStatus(willOpen);
            storageService.setDisponibility(willOpen);
            this.setState({ closed: !willOpen });
            // verify status after 6h
            setTimeout(() => this.verifyOpenStatus(), 6 * 60 * 60 * 1000)
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false, isButtonDisabled: true });
        setTimeout(() => this.setState({ isButtonDisabled: false }), 75000);

    }

    verifyOpenStatus = async () => {
        const res = partnerService.getRestaurentStatus();
        if (res.data.status !== 'PAUSED') {
            storageService.setDisponibility(true);
            this.setState({ closed: false });
            this.props.onClose();
        } else {
            setTimeout(() => this.verifyOpenStatus(), 60 * 1000);
        }

    }

    updateInventoryHook = (isOk) => {
        if (isOk) {
            this.props.changeDisableInventory();
            this.showMessage("Mis à jour avec succès", 'success');
        } else {
            this.showMessage("Erreur inattendu. Veuillez rafraichir l'app pour mettre à jour les catalogues.", "error");
        }
    }


    showMessage = (message, messageType) => {
        messageType = messageType || "info";
        toast[messageType](message);
    };

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.loading}>
                {this.state.page === PAGES.settings ?
                    <Box gap='medium' style={{ background: this.state.closed ? 'red' : 'none', opacity: this.state.closed ? '70%' : '100%', height: '100vh' }}>
                        {/*<Header onClose={this.props.onClose} background={this.state.closed ? 'red' : undefined} />*/}
                        {this.state.closed &&
                            <PausedState
                                onRefresh={this.changeDisponibilite}
                            />
                        }

                        {this.state.adminPopup &&
                            <Layer style={{ background: 'transparent' }}>
                                <AdminPopup onClose={() => this.setState({ adminPopup: false })} />
                            </Layer>

                        }

                        {!this.state.closed && !this.state.adminPopup && !this.state.showOpeningPopup && !this.state.showHolidayPopup &&
                            <LeftMenu
                                toDisponibility={() => this.viewDisponibilite()}
                                toHoraire={() => this.setState({ showOpeningPopup: true })}
                                toPrinter={() => this.setState({ page: PAGES.printer })}
                                onClose={() => this.props.onClose()}
                                pauseRestaurant={this.state.isButtonDisabled ? null : this.changeDisponibilite}
                                toHolidayMode={() => this.setState({showHolidayPopup: true})}
                            />}

                        {/*<Box pad={'medium'} gap='large' align="start" >
                            <Box
                                style={{ boxShadow: 'none', height: '100%' }}
                                pad='small'
                            >
                                <Text color={'#033076'} style={{ cursor: 'pointer', fontSize: FONT_SIZE.xlarge }}
                                    onClick={this.viewDisponibilite}
                                >Disponibilité du stock</Text>
                            </Box>
                            <Box
                                style={{ boxShadow: 'none', height: '100%' }}
                                pad='small'
                            >
                                <Text color={'#033076'} style={{ cursor: 'pointer', fontSize: FONT_SIZE.xlarge }}
                                    onClick={() => this.setState({ showOpeningPopup: true })}
                                >Horaires</Text>
                            </Box>
                            <Box
                                style={{ boxShadow: 'none', height: '100%' }}
                                pad='small'
                            >
                                <Text color={'#033076'} style={{ cursor: 'pointer', fontSize: FONT_SIZE.xlarge }}
                                    onClick={() => this.setState({ page: PAGES.printer })}
                                >Imprimante</Text>
                            </Box>
                    </Box
                        <Box style={{ position: 'absolute', bottom: 20 }} pad='medium' justify="center" align="center" width={'full'}>
                            <Box
                                onClick={this.state.isButtonDisabled ? null : this.changeDisponibilite}
                                background={!this.state.closed ? '#e61610' : '#72bb53'}
                                justify='center' align="center"
                                pad='large'
                                round={{ size: 'large' }}
                                style={{ boxShadow: 'none', height: '100%', opacity: this.state.isButtonDisabled ? 0.5 : 1 }}
                            >
                                {!this.state.closed ?
                                    <Text style={{ fontWeight: 'bold', fontSize: FONT_SIZE.xlarge }} color={'#fff'}>Suspendre les commandes</Text> :
                                    <Text style={{ fontWeight: 'bold', fontSize: FONT_SIZE.xlarge }} color={'#fff'}>Reprendre les commandes</Text>
                                }
                            </Box>
                        </Box>>*/}
                        {this.state.showOpeningPopup &&
                            <OpeningPopup
                                onClose={() => this.setState({ showOpeningPopup: false })}
                            />
                        }
                        {this.state.showHolidayPopup &&
                            <HolidayPopup
                                onClose={() => this.setState({ showHolidayPopup: false })}
                            />
                        }
                    </Box> :
                    this.state.page === PAGES.printer ?
                        <Printer onClose={() => this.setState({ page: PAGES.settings })}
                            showMessage={this.showMessage}
                        /> :
                        <DisponibilitePage
                            updateInventoryHook={this.updateInventoryHook}
                            disable_inventory={this.props.disable_inventory}
                            onClose={() => this.setState({ page: PAGES.settings })}
                            showMessage={this.showMessage}
                        />

                }
                <AdminAccessButton
                    onOpen={() => this.setState({ adminPopup: true })}
                />


                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BlockUi>

        )
    }
}

const Printer = ({ onClose, showMessage }) => {
    const [value, setValue] = useState(getPrinterIp());
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [_autoPrint, _setAutoPrint] = useState(getAutoPrint());
    const saveValue = () => {
        setPrinterIp(value);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
    }
    const printTestCommande = () => {
        const order = {
            "id": 43624,
            "collection_code": "9939",
            "total": "17,92 EUR",
            "platform": "DELIVEROO",
            "deliveroo_id": "10140019939",
            "object_id": "fr:e1c2cec0-e076-4614-92cc-0ab9896a1d3c",
            "service_type_ref": "PAPAFRITES",
            "status": "ACCEPTED",
            "private_ref": null,
            "service_type": "delivery",
            "expected_time": "2023-12-10T20:25:42.000Z",
            "confirmed_time": null,
            "order_note": "Commande note test",
            "customer_notes": "PAS DE COUVERTS",
            "coupon_codes": [],
            "customer": {
              "phone": "33175858334",
              "first_name": "Test Client",
              "access_code": "032900822"
            },
            "deals": {},
            "discounts": [],
            "charge": [],
            "partner_name": "Chiken Malika",
            "catalog_name": "PAPAFRITES - Grenoble",
            "delivery_time": null,
            "createdAt": "2023-12-10T20:15:46.738Z",
            "updatedAt": "2023-12-10T20:47:01.356Z",
            "catalog_identifier": "590965",
            "placed_at": "2023-12-10T20:15:16.000Z",
            "expected_pickup_at": "2023-12-10T20:25:42.000Z",
            "partner_id": "534",
            "test": null,
            "catalog_id": "1157",
            "extra_field_json": null,
            "items": [
              {
                "id": 84034,
                "pos_item_id": "Menu duo Classique",
                "product_name": "Menu duo Classique",
                "sku_name": "Menu duo Classique",
                "price": "22,40 EUR",
                "order_status": "PLACED",
                "deliveroo_order_id": "fr:e1c2cec0-e076-4614-92cc-0ab9896a1d3c",
                "quantity": 1,
                "total": "22,40 EUR",
                "note": null,
                "options": [
                  {
                    "ref": "x2 Paninis + x2 Frites + x2 Boissons 33cl",
                    "name": "x2 Paninis + x2 Frites + x2 Boissons 33cl",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  },
                  {
                    "ref": "Panini Chèvre miel",
                    "name": "Panini Chèvre miel",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  },
                  {
                    "ref": "Curry",
                    "name": "Curry",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  },
                  {
                    "ref": "Panini Cordon bleu",
                    "name": "Panini Cordon bleu",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  },
                  {
                    "ref": "samouraï",
                    "name": "samouraï",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  },
                  {
                    "ref": "x2 frites",
                    "name": "x2 frites",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  },
                  {
                    "ref": "Coca Cola Cherry",
                    "name": "Coca Cola Cherry",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  },
                  {
                    "ref": "Coca Cola Cherry",
                    "name": "Coca Cola Cherry",
                    "price": "0 EUR",
                    "quantity": 1,
                    "option_list_name": "Options"
                  }
                ],
                "createdAt": "2023-12-10T20:15:46.623Z",
                "updatedAt": "2023-12-10T20:15:49.841Z"
              }
            ],
          }
        printCommande(order, setLoading, showMessage || (() => {}))
    }
    return (
        <BlockUi tag="div" blocking={loading}>
        <Box gap='medium' width={'full'} align="center" justify="center">
            <Header onClose={onClose} />
            <Box width={'large'} pad='medium' gap="medium" align="center" justify="center">
                <Text size="xlarge">
                    Veuillez entrer la valeur de "IP Address". Cette valeur est imprimée lors de la première impression de l'imprimante
                </Text>
                <TextInput
                    placeholder="192.168.10.246"
                    value={value}
                    onChange={event => setValue(event.target.value)}
                />
                <Box
                    style={{ boxShadow: 'none', height: '100%' }}
                    pad='small'
                    background={COLOR.light_blue}
                    direction='row' gap="small"
                    border={{ size: 'medium', color: COLOR.light_blue }}
                    round={{ size: 'medium' }}
                    onClick={saveValue}
                >
                    <Save color={COLOR.dark_blue} />
                    <Text color={'#033076'} style={{ fontSize: FONT_SIZE.medium }}
                    >Sauvegarder</Text>

                </Box>

                {success && <Text>Sauvegardé</Text>}

                {value ?
                    <Button label="Accepter la connexion à l'imprimante"
                        onClick={() => window.open(`https://${value}:8043/socket.io/1/?t=1683115219099`, '_blank')}
                    /> : null
                }

                {value ?
                    <Button label="Test imprimante"
                        onClick={() => printTestCommande()}
                    /> : null
                }

                <Box direction="row" justify="center" align="center" gap="small" pad={{ top: 'medium' }}>
                    <Text color={'#033076'} style={{ fontSize: FONT_SIZE.small }}>
                        Dès la reception de commande :
                    </Text>
                    <Box>
                        <CheckBox
                            checked={_autoPrint}
                            label={'Auto print'}
                            onChange={event => {
                                _setAutoPrint(event.target.checked)
                                setAutoPrint(event.target.checked)
                            }}
                        />
                    </Box>
                </Box>

            </Box>
        </Box>

        </BlockUi>
    )
}

export default SettingsPage;