import { Box, Image, Layer } from "grommet";
import React, { useState } from "react";
import offline from "../../images/offline.png";
import partner_service from '../../services/partner_service';
import { FONT_SIZE } from "../../services/utils";
import StandardText from "../elements/standard_text";
import HelpPopup from "../help_popup";
import { Header } from "../home_page/index_v2";
import AdminPopup, { AdminAccessButton } from "../sign_in/admin";

const OfflinePage = ({ toHistoryPage, toSettingPage, currentTime }) => {
  const [help, setHelp] = useState();
  const [adminPopup, setAdminPopup] = useState();

  return (
    <Box
      width="full"
      justify="between"
      align="center"
      style={{ height: "100vh" }}
    >
      <Header
        toHistoryPage={toHistoryPage}
        toSettingPage={toSettingPage}
        help={() => setHelp(true)}
        currentTime={currentTime}
      />
      <Box
        width={"full"}
        justify="center"
        align="center"
        background={"#000"}
        style={{ height: "100%" }}
      >
        <Image width={210} src={offline} />
      </Box>
      <Box
        width={"full"}
        justify="center"
        align="center"
        style={{ minHeight: "auto" }}
        pad={"medium"}
      >
        <StandardText
          style={{ fontSize: FONT_SIZE.medium, fontWeight: "bold" }}
          label={"L’établissement est actuellement fermé."}
        />
        <br />
        <span style={{ fontSize: FONT_SIZE.medium }}>
          <strong>Réouverture automatique </strong> à {partner_service.getNextOpenHour()}
        </span>
      </Box>
      {help ? <HelpPopup setHelp={setHelp} /> : null}

      <AdminAccessButton onOpen={() => setAdminPopup(true)} />
      {adminPopup && (
        <Layer style={{ background: "transparent" }}>
          <AdminPopup onClose={() => setAdminPopup(false)} />
        </Layer>
      )}
    </Box>
  );
};

export default OfflinePage;
