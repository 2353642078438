import { Box, Spinner } from "grommet";
import React from "react";
import { COLOR } from "../../../services/utils";

export default function LoadingAnimation() {

    return (
        <Box align="center" justify="center" gap="small" width={'full'}>
            <Spinner size="xlarge" color={COLOR.dark_blue} />
        </Box>
    )
}